import * as React from 'react';
import { Router } from '@reach/router';
import MintComplete from '../components/MintComplete';
import { useMoralis } from 'react-moralis';
import useRefetchPageFromServer from '../hooks/useRefetchPageFromServer';
import { useEffect } from 'react';

export default () => {
    const { isAuthenticated, user } = useMoralis();
    const { refetchServerPage } = useRefetchPageFromServer();

    useEffect(() => {
        if (isAuthenticated && user) {
            (async () => {
                const ethAddress = user.get('ethAddress');
                await refetchServerPage(ethAddress);
            })();
        }
    }, [isAuthenticated, user]);

    return (
        <Router basepath="/mint-complete">
            <MintComplete
                /*@ts-ignore*/
                path="/:chain/:tokenAddress/:tokenId"
                styles={{
                    marginTop: ['0rem', '5rem'],
                }}
            />
        </Router>
    );
};
