import * as React from 'react';
import { useState } from 'react';
import {
    AspectRatio,
    Button,
    Flex,
    Image,
    ScaleFade,
    Text,
    useColorModeValue,
    useMediaQuery,
} from '@chakra-ui/react';
import { Link } from 'gatsby';
import G3LogoLight from '../../../assets/g3-light-logo.svg';
import G3LogoDark from '../../../assets/g3-dark-logo.svg';
import G3ALPHAPNG from '../../../assets/G3-ALPHA.png';
import G3ALPHAWebM from '../../../assets/G3-ALPHA.webm';
import Layout from '../base/Layout';

export default ({
    chain,
    tokenAddress,
    tokenId,
}: {
    chain?: string;
    tokenAddress?: string;
    tokenId?: string;
}) => {
    const logoImage = useColorModeValue(G3LogoLight, G3LogoDark);

    const [isMobile] = useMediaQuery('(max-width: 768px)');
    const [videoError, setVideoError] = useState(false);

    return (
        <Layout noContainer showConnectButton showNavLinks>
            <Flex
                direction="column"
                justify="center"
                align="center"
                margin="0 auto"
                minHeight="50vh"
            >
                <Link
                    to="/"
                    style={{
                        textDecoration: `none`,
                    }}
                >
                    <Image src={logoImage} width={['15rem', '25rem']} />
                </Link>
                <AspectRatio
                    maxW={['70vw', '30vw']}
                    ratio={1}
                    w="100%"
                    overflow="hidden"
                >
                    <ScaleFade in style={{ height: '100%' }}>
                        {videoError || isMobile ? (
                            <Image width={'100%'} src={G3ALPHAPNG} />
                        ) : (
                            <video
                                height={'100%'}
                                width={'100%'}
                                controls={false}
                                autoPlay
                                muted
                                loop
                                onError={() => setVideoError(true)}
                                playsInline
                            >
                                <source src={G3ALPHAWebM} type="video/webm" />
                            </video>
                        )}
                    </ScaleFade>
                </AspectRatio>
                <Text
                    opacity="0.5"
                    margin="0.5rem 1rem 1rem 1rem"
                    fontSize={['1.2rem', '1.5rem']}
                    textAlign="center"
                >
                    {`You successfully minted an ALPHA Pass (#${tokenId}) NFT!`}
                </Text>

                <Link to={`/token/${chain}/${tokenAddress}/${tokenId}`}>
                    <Button
                        size={isMobile ? 'md' : 'lg'}
                        background={'#AB2FB7'}
                        color="white"
                    >
                        View My NFT →
                    </Button>
                </Link>
            </Flex>
        </Layout>
    );
};
